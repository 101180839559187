import React, { useState } from "react";
import Popover from "@/components/shared/popover";
import { useSearchParams } from "next/navigation";
// import { Languages } from "lucide-react";
import Link from "next/link";

export const TopTabLink = ({
  label,
  current,
  setCurrent,
  newURL,
  locale,
  isTab = true,
}: {
  label: any;
  current: any;
  setCurrent: any;
  newURL?: any;
  locale: any;
  isTab?: any;
}) => {
  // const pathname = usePathname();
  const searchParams = new URLSearchParams(useSearchParams());

  const [openMobilePopover, setOpenMobilePopover] = useState(false);

  const isOpen = openMobilePopover;
  const setOpen = setOpenMobilePopover;

  const options = [
    { key: "top2", label: "2 Days Top" },
    // { key: "top7", label: "7 Days Top" },
    // { key: "top30", label: "30 Days Top" }
  ];

  return (
    <Popover
      content={
        <div className="w-full rounded-md bg-[#202124] p-2 md:w-40">
          {options.map((option) => {
            // const remainingPath = pathname.split("/").slice(3).join("/");
            // const newPath = `${pathname}${remainingPath ? "/" + remainingPath : ""}${searchParams.toString() ? "?" + searchParams.toString() : ""}`;
            const query = {
              filter: option.key,
              style: searchParams.get("style") || ["all"],
            };
            return (
              <Link
                prefetch={false}
                key={option.key}
                href={{ pathname: newURL, query }}
                onClick={() => {
                  setOpen(false);
                  setCurrent(option.key);
                  // console.log(option.key)
                }}
                className="flex w-full items-center justify-start space-x-2 rounded-md p-2 text-left text-sm text-white transition-all duration-75 hover:bg-[#4d4f59] active:bg-[#4d4f59]"
              >
                {locale[option.key]}
              </Link>
            );
          })}
        </div>
      }
      openPopover={isOpen}
      setOpenPopover={setOpen}
    >
      <button
        onClick={() => {
          setOpen(!isOpen);
        }}
        title="Change Tab"
        className={`${
          options.some((option) => option.key === current)
            ? "border-[0.5px] border-[#DD0772] bg-[#DD0772]"
            : "border-[0.5px] border-[#3C3C3C] bg-[#2B2D31]"
        } xs:ml-0 mr-2.5 whitespace-nowrap rounded-md px-4 py-[7px] text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0 focus:ring-gray-300`}
      >
        <div className="capitalize">{locale[label]}</div>
      </button>
    </Popover>
  );
};
